import { Product } from "@/interfaces/product";
import { SaveReconciliationData } from "@/interfaces/reconciliation";
import { Room } from "@/interfaces/room";
import { inventoryReconciliationService } from "@/services/InventoryReconciliation.service";
import { messagesService } from "@/services/messages.service";
import { roomService } from "@/services/room.service";
import { PageNavAction } from "@/types/types";
import {
  ActionsSubheaderComponent,
  ActionSubheader
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { TranslateResult } from "vue-i18n";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./createBatch.template.vue";

@Component({
  mixins: [Template]
})
export default class CreateBatchComponent extends Vue {
  public generalActions: ActionSubheader[] = inventoryReconciliationService.getGeneralActions();
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public units: Array<{ label: string | TranslateResult; value: string }> = [];
  public defaultProductsList: Product[] = [];
  public productsList: Product[] = [];
  public selectedProduct: Product | null = null;
  public loadingProducts = false;
  public productSearch: string | null = null;
  public roomList: Room[] = [];
  public loadingRooms = true;
  public loading = false;
  public model: SaveReconciliationData = {
    sku: "",
    locations: [
      {
        quantity: null,
        inventory_location_id: null
      }
    ],
    tracking_id: "",
    quantity_value: null,
    quantity_unit: "",
    usable_weight_value: null,
    usable_weight_unit: null,
    weight_per_unit_value: null,
    weight_per_unit_unit: null
  };

  /**
   * Executes a search for products by name (debounced).
   */
  public dSearchProducts = debounce(() => {
    this.loadProducts();
  }, 500);

  /**
   * Check if product requires weighin based on NewBatch comparation
   */
  public get productRequiresWeighin() {
    return (
      this.selectedProduct &&
      !this.selectedProduct.requires_weighing &&
      (this.selectedProduct && this.selectedProduct.marijuana)
    );
  }

  public async save() {
    this.loading = true;
    // Check model Integrity
    if (!(await this.$validator.validateAll())) {
      this.loading = false;
      return;
    }

    // Extra data maping
    this.model.quantity_value = this.model.quantity_value
      ? parseInt((this.model.quantity_value as unknown) as string, 10)
      : null;
    this.model.quantity_unit = this.selectedProduct!.unit;

    this.model.locations[0].quantity = this.model.quantity_value;
    if (!this.productRequiresWeighin) {
      this.model.usable_weight_value = null;
      this.model.usable_weight_unit = null;
      this.model.weight_per_unit_value = null;
      this.model.weight_per_unit_unit = null;
    } else {
      this.model.requires_weighin = 1;
    }

    // Save Batch
    const response = await inventoryReconciliationService.saveReconciliationBatch(
      this.model
    );
    if (response) {
      this.$router.push({
        name: "inv-reco-list",
        query: {
          date: this.$route.params.date
        }
      });
    } else {
      this.loading = false;
    }
  }

  // Detect if input changed and a product should be searched
  @Watch("productSearch")
  public searchProducts() {
    if (!this.model.sku || !this.productSearch) {
      this.dSearchProducts();
    }
  }

  // Load products, NOT DEBOUNCED
  public async loadProducts() {
    if (
      (!this.productSearch && this.defaultProductsList.length) ||
      this.model.sku
    ) {
      this.productsList = cloneDeep(this.defaultProductsList);
      return;
    }
    this.loadingProducts = true;
    this.productsList = await inventoryReconciliationService.getProducts(
      this.productSearch!
    );
    if (!this.productSearch) {
      this.defaultProductsList = cloneDeep(this.productsList);
    }
    this.loadingProducts = false;
  }

  public productChanged() {
    this.model.sku = (this.selectedProduct && this.selectedProduct.sku) || null;
  }

  protected async mounted() {
    this.model.tracking_id = this.$route.params.packageTag;
    this.setPageNav(this.pageNav);
    this.loadProducts();
    this.roomList = await roomService.getAll();
    this.loadingRooms = false;
    this.units = [
      {
        label: this.$t("limit.grams"),
        value: "g"
      },
      {
        label: this.$t("limit.milligrams"),
        value: "mg"
      },
      {
        label: this.$t("limit.ounces"),
        value: "oz"
      }
    ];
  }

  private back() {
    this.$router.back();
  }

  private get pageNav() {
    return {
      title: "metrc.batch_reconcile",
      leftActions: {
        component: ActionsSubheaderComponent,
        props: {
          generalActions: [
            {
              icon: "fal fa-chevron-left",
              action: this.back
            }
          ]
        }
      },
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            id: "btn_save",
            vuetifyProps: () => ({
              fab: true,
              small: true,
              disabled: this.loading
            }),
            action: this.save
          }
        ]
      }
    };
  }
}
