import { EventBus } from "@/event-bus";
import { Component, Vue } from "vue-property-decorator";
import Template from "./loadingWindow.template.vue";

@Component({
  mixins: [Template]
})
export default class LoadingWindowComponent extends Vue {
  public show = true;
  public className = "";
  public errorList: Array<{ message: string }> = [];
  public close() {
    this.$emit("reject");
  }

  public handleEvent(event: {
    show: boolean;
    errorList: Array<{ message: string }>;
    className?: string;
  }) {
    this.show = event.show;
    this.className = event.className || "";
    this.errorList = event.errorList;
    if (!this.show) {
      this.$emit("resolve");
    }
  }

  public accept() {
    this.$emit("reject");
  }

  public mounted() {
    EventBus.$on("mtrcLoadingEvent", this.handleEvent);
  }
}
