import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";

export const InventoryReconciliationTableMetadata: TableHeader[] = [
  {
    value: "package_tag",
    label: i18n.t("metrc.package_tag").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "biotrack_id",
    label: i18n.t("metrc.biotrack_id").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "item_reconciles",
    label: i18n.t("metrc.item_reconciles").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "quantity_reconciles",
    label: i18n.t("metrc.quantity_reconciles").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "unit_of_measure_reconciles",
    label: i18n.t("metrc.unit_of_measure_reconciles").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  }
];

export const itemsReconciles = [
  {
    value: "batchname_values",
    fieldComponent: TableFieldType.string
  },
  {
    value: "empty"
  },
  {
    value: "batch_product_name",
    fieldComponent: TableFieldType.string
  },
  {
    value: "quantity",
    fieldComponent: TableFieldType.numericField
  },
  {
    value: "unit_of_measure",
    fieldComponent: TableFieldType.string
  }
];
