import {
  Batch,
  BatchData,
  ParsedReconciliationData
} from "@/interfaces/reconciliation";
import { tableService } from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./TableItemReconciles.template.vue";

export interface LabelValue {
  label: string;
  value: string;
}

@Component({
  mixins: [Template]
})
export default class TableItemsReconciles extends Vue {
  @Prop({ default: () => [] })
  public list!: ParsedReconciliationData[];
  @Prop({ required: true })
  public fieldsToShow!: LabelValue[];
  @Prop({ required: true })
  public item!: Batch;

  get items() {
    return this.list.map(batch => Object.assign(batch, { empty: "" }));
  }

  public setPrice(price?: number, discount?: number) {
    const count = (price || 0) - (discount || 0);
    return count > 0 ? count.toFixed(2) : count;
  }

  public formatedText(item: BatchData, label: string) {
    const format = tableService.formatText(item, label);

    if (label === "unit_of_measure") {
      if (!item.unit_of_measure) {
        return "--";
      }
      switch (item.unit_of_measure) {
        case "g":
          return "Grams";
        case "u":
          return "Units";
        default:
          return item.unit_of_measure;
      }
    }

    return format || "--";
  }
}
