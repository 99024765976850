import MetrcDetailsComponent from "@/components/inventory/batchForm/metrcDetails/MetrcDetails.component";
import { EventBus } from "@/event-bus";
import { BatchDetail, ProductVariant } from "@/interfaces/batch";
import { ReconciliationPayload } from "@/interfaces/traceability";
import { batchService } from "@/services/batch.service";
import { traceabilityService } from "@/services/traceability.service";
import { PageNavAction } from "@/types/types";
import { CallbackPromise } from "helix-vue-components";
import isEmpty from "lodash/isEmpty";
import omitBy from "lodash/omitBy";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import LoadingWindowComponent from "../../loadingWindow/loadingWindow.component";
import Template from "./batchReconciliation.template.vue";

const namespace = "BatchModule";

@Component({
  mixins: [Template],
  components: {
    MetrcDetailsComponent
  }
})
export default class BatchReconciliationComponent extends Vue {
  public batchLoaded = false;
  public loading = false;
  public loadingWindow = false;
  public recoRes: { response: any; success: boolean } | null = null;
  public productVariant: ProductVariant | null = null;
  public reconciliationData: {
    payload: ReconciliationPayload;
    hasChanged: boolean;
  } | null = null;

  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  @Getter("currentBatch", { namespace })
  public batch!: BatchDetail | null;

  @Action("findBatch", { namespace })
  private findBatch!: CallbackPromise<void>;

  /**
   * @method to unset current Batch
   */
  @Action("unsetCurrentBatch", { namespace })
  private unsetBatch!: CallbackPromise<void>;

  public reconciliationChanged(data: {
    payload: ReconciliationPayload;
    hasChanged: boolean;
  }) {
    this.reconciliationData = data;
  }

  public async saveBatch() {
    this.loading = true;
    if (this.reconciliationData && this.reconciliationData.hasChanged) {
      const body: Partial<ReconciliationPayload> = omitBy(
        JSON.parse(JSON.stringify(this.reconciliationData.payload)),
        isEmpty
      );
      if (body.remote) {
        this.loadingWindow = true;
        this.$modals
          .load(LoadingWindowComponent, {
            closable: false,
            size: "fit",
            positionY: "center",
            positionX: "center",
            backdrop: true
          })
          .catch(() => {
            // nothing to do
          });
      }
      const response = await traceabilityService.reconcileBatch(
        body,
        this.loadingWindow
      );
      if (response && response.errors) {
        EventBus.$emit("mtrcLoadingEvent", {
          show: true,
          errorList: response.errors || []
        });
        this.recoRes = { response, success: false };
      } else {
        if (body.local && body.local.adjust) {
          await this.findBatch({ uid: this.$route.params.batchuid });
        }
        EventBus.$emit("mtrcLoadingEvent", {
          show: false
        });
        this.recoRes = { response, success: true };
        this.$emit("batchReconciled", {
          response,
          success: true,
          batch_uid: this.$route.params.batchuid
        });
      }
      this.loading = false;
    }
  }

  public cancel() {
    this.$router.back();
  }

  protected created() {
    this.setPageNav({
      title: "metrc.batch_reconcile",
      isLoading: () => this.loading || !this.batchLoaded,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.saveBatch,
            vuetifyProps: () => ({
              loading: this.loading,
              disabled:
                !this.reconciliationData || !this.reconciliationData.hasChanged,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            action: this.cancel,
            vuetifyProps: () => ({
              loading: this.loading,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
  }

  protected async mounted() {
    this.loading = true;
    await this.findBatch({ uid: this.$route.params.batchuid });
    batchService
      .getProductVariant(this.batch!.sku)
      .then(data => (this.productVariant = data));
    this.$nextTick(() => {
      this.batchLoaded = true;
      this.loading = false;
    });
  }

  protected beforeDestroy() {
    this.unsetBatch();
  }
}
