import { Location } from "@/interfaces/location";
import { ReconciliationData } from "@/interfaces/reconciliation";
import { User } from "@/interfaces/user";
import { BooleanCheck } from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./ReconciliationSelectField.template.vue";

const namespace = "AuthModule";

@Component({
  mixins: [Template]
})
export default class ReconciliationSelectFieldComponent extends Vue {
  public locations: Location[] = [];
  @Prop({ default: null })
  public date!: string;

  @Prop({ required: true })
  public tableStats!: () => ReconciliationData;
  @Prop()
  public loading!: BooleanCheck;
  @Getter("currentLocation", { namespace })
  public currentLocation!: Location;
  @Getter("user", { namespace })
  public user!: User;

  public requestedDate: string | Date | null = null;

  public customDateFormat = "DD-MM-YYYY";
  public pickerFormat = "dd-MM-yyyy";

  public get stats() {
    return this.tableStats();
  }

  public get isLoading() {
    return this.loading();
  }

  public emitUpdate(): void {
    if (this.requestedDate) {
      this.$emit("updateData", this.requestedDate);
    }
  }

  protected async created() {
    if (this.date) {
      this.requestedDate = new Date(
        new Date(this.date).getTime() + 12 * 60 * 60 * 1000
      );
    }
    this.customDateFormat =
      this.currentLocation!.date_format || this.customDateFormat;
    this.pickerFormat = this.customDateFormat
      .split("-")
      .map(block => {
        if (block !== "MM") {
          return block.toLowerCase();
        }
        return block;
      })
      .join("-");
    this.emitUpdate();
  }
}
