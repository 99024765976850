import TableItemReconciles from "@/components/metrc/inventoryReconciliation/itemReconciles/TableItemReconciles.component";
import { ResetService } from "@/decorators/resetService.decorator";
import { Batch, ReconciliationData } from "@/interfaces/reconciliation";
import {
  InventoryReconciliationTableMetadata,
  itemsReconciles
} from "@/metadata/inventoryReconciliation";
import { inventoryReconciliationService } from "@/services/InventoryReconciliation.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import ReconciliationSelectFieldComponent from "../reconciliationSelectField/ReconciliationSelectField.component";
import Template from "./reconciliationList.template.vue";

const namespace: string = "ReconciliationModule";

@Component({
  mixins: [Template],
  components: {
    TableComponent,
    TableItemReconciles
  }
})
@ResetService(inventoryReconciliationService)
export default class ReconciliationListComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Action("loadReconciliations", { namespace })
  public getReconciliationAction!: Callback;
  @Action("resetReconciliations", { namespace })
  public resetReconciliationsAction!: Callback;
  @Getter("headerData", { namespace })
  public headerData!: ReconciliationData;
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  @Getter("reconciliations", { namespace })
  public reconciliations!: Batch[];
  public fieldsToShow = itemsReconciles;
  public selectedDate: string | null = null;
  public headers: TableHeader[] = InventoryReconciliationTableMetadata;
  public rowActions: TableAction[] = inventoryReconciliationService.getRowActions();

  public changePagination(pagination: TablePagination) {
    const paginate = inventoryReconciliationService.paginationAction();
    paginate(pagination);
  }

  protected mounted() {
    this.resetReconciliationsAction();
    this.setPageNav({
      title: "metrc.inventory_reconciliation",
      secondaryActions: {
        component: ReconciliationSelectFieldComponent,
        props: {
          class: "pb-4",
          tableStats: () => ({
            total: this.headerData.total,
            reconcilable: this.headerData.reconcilable,
            synchronized: this.headerData.synchronized
          }),
          loading: () => this.loading,
          date: this.$route.query.date,
          ref: "search-form"
        },
        events: [
          {
            event: "updateData",
            callback: this.updateData
          }
        ]
      }
    });
  }
  private async updateData(selectedDate: Date) {
    this.selectedDate = selectedDate.toISOString().split("T")[0];
    this.$router.push({
      name: "inv-reco-list",
      query: { date: this.selectedDate }
    });
    inventoryReconciliationService.setQuery({
      date: this.selectedDate!,
      page: (this.pagination && this.pagination.currentPage) || 1,
      itemsPerPage: (this.pagination && this.pagination.itemsPerPage) || 10
    });
    this.getReconciliationAction(this.selectedDate);
  }
}
